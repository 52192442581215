import React from 'react';
import { GrLinkedin } from 'react-icons/gr';
import DecorElement from '../../elements/DecorElement';

const Intro = () => {
  return (
    <>
      <section id='top'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <DecorElement />
              <h2 className='mb-3 title-big'>
                Реалізуйте свій потенціал, працюючи над проектами, що мають
                суспільне значення!
              </h2>
              <div className='s-30'></div>
              <h3>
                Отримайте цінний досвід та розвиньте свої навички разом з нами,
                працюючи над важливими проектами, які спрямовані на покращення
                життя громади.
              </h3>
              <div className='s-30'></div>
              <p className='title-part'>
                Ми пропонуємо унікальну платформу для тих, хто хоче реалізувати
                свої знання та навички в реальних проектах з соціальним
                значенням. Наша ціль — створити середовище, де кожен зможе
                внести свій вклад у важливі проекти та отримати необхідний
                досвід для подальшого професійного зростання.
              </p>
              <div className='s-30'></div>
              <a
                href='https://forms.gle/4nA3v4tKXY5Jgns56'
                target='blank'
                className='button'
              >
                Приєднатися
              </a>
              <div className='s-60'></div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='s-80'></div>
              <h3 className='title-intro mb-5 tc'>
                Хто ми і чому наша робота важлива?
              </h3>
              <p className='title-part pt-5 pb-5 px-5 r-24 bg-pr3'>
                Ми - платформа, яка надає можливість молодим та амбітним
                фахівцям реалізувати свій потенціал, працюючи над проектами з
                соціальним значенням. Наша ініціатива спрямована на те, щоб
                допомогти випускникам курсів та вузів знайти своє місце в
                професійному світі, розвиваючи свої навички в реальному робочому
                середовищі.
              </p>
              <div className='s-10'></div>
              <p className='title-part pt-5 pb-5 px-5 r-24 bg-pr5'>
                У нас є чітке бачення того, як можна використовувати технології
                для покращення життя громади. Через нашу платформу ми об'єднуємо
                талановитих індивідів, які хочуть працювати над важливими
                проектами, які можуть зробити позитивний вплив на суспільство.
              </p>
              <div className='s-10'></div>
              <p className='title-part pt-5 pb-5 px-5 r-24 bg-pr2'>
                Ми надаємо нашим учасникам можливість працювати в команді,
                взаємодіяти з іншими фахівцями та отримувати досвід, який
                допоможе їм у подальшому професійному житті. Таким чином, кожен
                учасник не тільки розвиває свої навички, але й вносить вклад у
                проекти, які можуть змінити на краще життя громади.
              </p>
              <div className='s-30'></div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='s-60'></div>
              <h3 className='title-intro mb-4 tc'>
                Наш підхід до реалізації соціально значущих проектів
              </h3>
              <h5 className='lh-15'>
                Ми пропонуємо платформу для взаємодії та реалізації проектів,
                які спрямовані на вирішення актуальних суспільних проблем. Наш
                підхід базується на залученні талановитих фахівців, які хочуть
                зробити свій внесок у покращення життя громади.
              </h5>
              <div className='s-30'></div>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <p className='title-sub pt-3 pb-3 px-3 r-24 bg-pr5'>
                    {' '}
                    Симуляція реального робочого середовища:
                  </p>
                  <ul style={{ listStyle: 'disc' }}>
                    <li className='title-part'>
                      Ми створюємо умови для того, щоб кожен учасник міг
                      розширити свої навички програмування, дизайну, управління
                      проектами та інших ключових областей.
                    </li>
                  </ul>
                </li>
                <div className='s-30'></div>
                <li>
                  <p className='title-sub pt-3 pb-3 px-3 r-24 bg-pr3'>
                    {' '}
                    Соціально значущі проекти:
                  </p>
                  <ul style={{ listStyle: 'disc' }}>
                    <li className='title-part'>
                      Ми відібрали ряд важливих тем та проблем, які можна
                      вирішити за допомогою технологій. Наші учасники працюють
                      над проектами, які мають реальний вплив на суспільство.
                    </li>
                  </ul>
                </li>
                <div className='s-30'></div>
                <li>
                  <p className='title-sub pt-3 pb-3 px-3 r-24 bg-pr5'>
                    {' '}
                    Підтримка та наставництво:
                  </p>
                  <ul style={{ listStyle: 'disc' }}>
                    <li className='title-part'>
                      Ми забезпечуємо підтримку наших учасників, надаючи доступ
                      до досвідчених менторів та кураторів, які можуть допомогти
                      з професійним розвитком та вирішенням викликів проекту.
                    </li>
                  </ul>
                </li>
                <div className='s-30'></div>
                <li>
                  <p className='title-sub pt-3 pb-3 px-3 r-24 bg-pr3'>
                    {' '}
                    Колективний внесок:
                  </p>
                  <ul style={{ listStyle: 'disc' }}>
                    <li className='title-part'>
                      Об'єднуючи розуміння та зусилля наших учасників, ми
                      працюємо над тим, щоб приносити позитивні зміни в
                      суспільство через технології.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section id='feed-back'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='s-80'></div>
              <h3 className='title-intro mb-4 tc'>
                Наш вплив на професійний розвиток учасників
              </h3>
              <h5 className='lh-15'>
                Ми горді тим, що можемо надати платформу для тих, хто хоче
                реалізувати свої навички та внести свій вклад у соціально
                значущі проекти. Ось декілька історій успіху від наших
                учасників, які змогли покращити свої професійні навички та
                знайти своє місце в галузі.
              </h5>
              <div className='s-30'></div>
              <div className='flex jcc wrapper'>
                <div className='w-40 px-50 tc item'>
                  <img
                    className='w-100'
                    src='https://ca.slack-edge.com/T020P9LH6KC-U05T7LFAG3A-2729b59ebbf7-512'
                    alt='mariya'
                  />
                  <div className='s-10'></div>
                  <h4>
                    <a href='https://www.linkedin.com/in/maria-zhorzholiani-6023a3205/'>
                      Mariya Zhorzholiani <GrLinkedin />
                    </a>
                  </h4>
                  <p>UX/UI Designer</p>
                  <p>15.9.2023 - 17.10.2023</p>
                  <p>
                    На проєкті Pogovorimo, я займалася дизайном Ui/Ux для того,
                    щоб створити дашборд адміна та зробити за ним мобільну
                    версію. У команді @Roman Boreyko, @Ivan Bilobrov для
                    досягнення мети, як створювати дашборди та адаптацію його
                    під різні девайси. Я дізналася для себе, як це працювати в
                    команді
                  </p>
                </div>
                <div className='w-40 px-50 tc item'>
                  <img
                    className='w-100'
                    src='https://ca.slack-edge.com/T020P9LH6KC-U05QTCNQ35F-71d2c6fef5cc-512'
                    alt='roman'
                  />
                  <div className='s-10'></div>
                  <h4>
                    <a href='https://www.linkedin.com/in/roman-boreyiko-0193211b9/'>
                      Roman Boreyko <GrLinkedin />
                    </a>
                  </h4>
                  <p>Project Manager</p>
                  <p>31.8.2023 - 23.10.2023</p>
                  <p>
                    {' '}
                    На проєкті Pogovorimo, я займався фіксацією прогресу в
                    Трелло, організацією робочого процессу, онбордингом нових
                    дизайнерів та розробників, щоб створити "Особистий Кабінет".
                    В команді ми реалізовували Бренд Бук, Дашборд для адмінки,
                    деякі скріни Ососбістого Кабінета для Участника, Проектного
                    Менеджера, Партнера. Я більше дізнався про процесс командної
                    розробки на гітхабі.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='s-60'></div>
              <h3 className='title-intro mb-4 tc'>
                Станьте частиною нашого комьюнити та реалізуйте свій потенціал
              </h3>
              <h5 className='lh-15'>
                Долучитися до нас - це шанс працювати над проектами, які мають
                суспільне значення, та розвивати свої професійні навички в
                командному середовищі. Ми шукаємо мотивованих індивідів, які
                готові внести свій вклад у реалізацію важливих проектів та
                допомогти нам створювати позитивні зміни в суспільстві.
              </h5>
              <div className='s-30'></div>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <p className='title-sub pt-3 pb-3 px-3 r-24 bg-pr5'>
                    {' '}
                    Навички та кваліфікація:
                  </p>
                  <ul style={{ listStyle: 'disc' }}>
                    <li className='title-part'>
                      Ми цінуємо знання у сфері програмування, дизайну,
                      управління проектами та інших областях, які сприяють
                      успішній реалізації наших проектів.
                    </li>
                  </ul>
                </li>
                <div className='s-30'></div>
                <li>
                  <p className='title-sub pt-3 pb-3 px-3 r-24 bg-pr3'>
                    {' '}
                    Менторство та підтримка:
                  </p>
                  <ul style={{ listStyle: 'disc' }}>
                    <li className='title-part'>
                      Ми надаємо підтримку та наставництво наших учасників,
                      забезпечуючи їм необхідні ресурси для ефективної роботи та
                      професійного зростання.
                    </li>
                  </ul>
                </li>
                <div className='s-30'></div>
                <li>
                  <p className='title-sub pt-3 pb-3 px-3 r-24 bg-pr5'>
                    {' '}
                    Робота над реальними проектами:
                  </p>
                  <ul style={{ listStyle: 'disc' }}>
                    <li className='title-part'>
                      Учасники матимуть можливість працювати над проектами, які
                      вирішують важливі соціальні проблеми та мають реальний
                      вплив на життя громади.
                    </li>
                  </ul>
                </li>
                <div className='s-30'></div>
                <li>
                  <p className='title-sub pt-3 pb-3 px-3 r-24 bg-pr3'>
                    {' '}
                    Співтовариство однодумців:
                  </p>
                  <ul style={{ listStyle: 'disc' }}>
                    <li className='title-part'>
                      Долучившись до нас, ви зможете спілкуватися та
                      співпрацювати з іншими талановитими індивідами, які також
                      хочуть робити позитивний вплив на суспільство.
                    </li>
                  </ul>
                </li>
              </ul>
              <div className='s-30'></div>
              <div className='tc'>
                <a
                  href='https://forms.gle/4nA3v4tKXY5Jgns56'
                  target='blank'
                  className='button'
                >
                  Дізнатися більше та приєднатися
                </a>
              </div>
              <div className='s-80'></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { Intro };
