import React from 'react'
import logotype from '../assets/img/svg/logotype.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Footer = () => {
  const year = new Date()
  return (
    <footer className='w-100 bg-light'>
      <div className='container'>
        <div className="s-40"></div>
        <div className="row">
          <div className="col-lg-8">
            <a href='#top' aria-label="Pogovorimo - Головна"><LazyLoadImage src={logotype} width={162} height={50} alt="Pogovorimo" /></a>
          </div>
          <div className="col-lg-4 tr">
            <p>Громадська організація «ПОГОВОРИМО»<br />
            ЄДРПОУ: 44818137</p>
          </div>
        </div>
        <div className="s-40"></div>
        <div className="row">
          <div className="col-lg-8">
            <p>@{year.getFullYear()} - Pogovorimo. Усі права захищено</p>
          </div>
          <div className="col-lg-4 tr">
            <a href='/privacy' aria-label="Політика конфіденційності">Політика конфіденційності</a>
          </div>
        </div>
        <div className="s-20"></div>
      </div>
    </footer>
  )
}

export {Footer}
